<template>
  <div class="cooperation">
    <Navigation :path="path" />
    <img src="~img/cooperation/01.png" />
    <div class="one">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4><span>5大</span>产品优势</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <div class="top-box hidden-xs-only">
        <div class="left">
          <img src="~img/cooperation/02.png" />
          <p class="p1">科学·思维融合教育</p>
          <p class="p2">抢占刚需蓝海赛道</p>
        </div>
        <div class="shu"></div>
        <div class="right">
          <img src="~img/cooperation/03.png" />
          <p class="p1">高品质的课程体系</p>
          <p class="p2">教研实力雄厚</p>
        </div>
      </div>
      <ul class="pc hidden-xs-only">
        <li>
          <img src="~img/cooperation/04.png" />
          <p class="p1">好品牌易传播</p>
          <p class="p2">迅速打开当地市场</p>
        </li>
        <li>
          <img src="~img/cooperation/05.png" />
          <p class="p1">成熟的商业模式</p>
          <p class="p2">标准化运营好复制</p>
        </li>
        <li>
          <img src="~img/cooperation/06.png" />
          <p class="p1">一体化的服务支持</p>
          <p class="p2">快速拥有科学馆</p>
        </li>
      </ul>
      <ul class="web hidden-sm-and-up">
        <li>
          <img src="~img/cooperation/02.png" />
          <p class="p1">科学·思维融合教育</p>
          <p class="p2">抢占刚需蓝海赛道</p>
        </li>
        <li>
          <img src="~img/cooperation/03.png" />
          <p class="p1">高品质的课程体系</p>
          <p class="p2">教研实力雄厚</p>
        </li>
        <li>
          <img src="~img/cooperation/04.png" />
          <p class="p1">好品牌易传播</p>
          <p class="p2">迅速打开当地市场</p>
        </li>
        <li>
          <img src="~img/cooperation/05.png" />
          <p class="p1">成熟的商业模式</p>
          <p class="p2">标准化运营好复制</p>
        </li>
        <li>
          <img src="~img/cooperation/06.png" />
          <p class="p1">一体化的服务支持</p>
          <p class="p2">快速拥有科学馆</p>
        </li>
      </ul>
    </div>
    <div class="tow">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>服务支持体系</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul class="bg-box">
        <li>
          <img src="~img/cooperation/07.png">
          <p>筹备支持</p>
          <div class="text">
            1. 品牌形象支持：总部提供整套VI系统和校区装修设计指导，统一爱因斯坦思维科学馆的品牌形象，总部授权合作伙伴在合同期内享有合法使用“爱因斯坦思维”科学馆注册商标标识的权利。<br />
            2. 选址建校支持：从选址、定址、装修、执照办理、到开业的全套指导，使原本复杂的开店过程变得简单化。<br />
            3. 开业到店支持：总部提供开业方案拟定、开业宣传方案建议、督导到店支持。<br />
          </div>
        </li>
        <li>
          <img src="~img/cooperation/08.png">
          <p>运营管理</p>
          <div class="text">
            1. 经营管理支持：总部为加盟商提供一整套专业的标准化运营手册以及定期的培训，让加盟商轻松管理校区。<br />
            2. 人员招聘支持：完整的人员招聘方案，从招聘途径 、人员配备、薪资标准等全方位支持。<br />
          </div>
        </li>
        <li>
          <img src="~img/cooperation/09.png">
          <p>教学支持</p>
          <div class="text">
            1. 体系化完整课程：提供3-12岁专业完整的课程体系，包括提供教学讲义，教案，视频动画、电子绘本等形式，降低教师备课难度。<br />
            2. 课程更新升级研发：根据课程及市场需求，不断更新升级现有课程、开发新课程。<br />
          </div>
        </li>
        <li>
          <img src="~img/cooperation/10.png">
          <p>市场推广</p>
          <div class="text">
            1. 市场招生支持：提供经公司多年市场经验总结的招生方案，使招生这一销售过程中最重要的环节变得简单化。<br />
            2. 发展规划支持：为校区进行当地市场分析，共同制定发展规划，分析发展阻力与市场机会，制定相应措施。<br />
            3. 网络推广支持：总部利用各种网络平台，对我们的品牌加以持续宣传，对直营店和加盟店一视同仁，利用网络传递各种及时、丰富的资讯，使各个校区同步发展。<br />
          </div>
        </li>
        <li>
          <img src="~img/cooperation/11.png">
          <p>培训支持</p>
          <div class="text">
            1. 教学教务培训：线上线下为教学教务老师提供系统完善的课程培训，总部定期开课，集中授课，注重考核以达到培训效果。<br />
            2. 市场咨询培训：由多年市场经验的老师进行整套市场方案分享，实操练习以达到考核标准。<br />
            3.校长管理培训：为校长准备了校区实质运营流程方案，解决校区实质性问题。<br />
          </div>
        </li>
        <li>
          <img src="~img/cooperation/12.png">
          <p>赛事平台</p>
          <div class="text">
            1. 国内竞赛支持：爱因斯坦思维科学馆为会员提供参加高水准的全国青少年科技大赛等各类科技比赛的平台。<br />
            2. 国际游学支持：爱因斯坦思维科学馆为会员每年提供到英国、韩国、德国等国家进行科技赛事游学的机会。<br />
          </div>
        </li>
        <li>
          <img src="~img/cooperation/13.png">
          <p>智能平台</p>
          <div class="text">
            1. 授课互动大屏系统：提供在线化考勤，帮助校区为孩子提供便利有趣的交互体验课程，并借助硬件设备，以录像、录音等形式为孩子记录课堂的闪光时刻。<br />
            2. 教师移动端+Web端在线系统：为老师提供便利的师训服务平台，并为授课提供备课、班级管理、课程安排提醒、学生课堂影音分享等助教支持。<br />
            3. 学校后台管理系统：为校区提供便捷的校区管理支持。<br />
            4. 学生APP+Web端在线系统：为学生提供上课提醒、课程复习、作业上传评测、趣味课外知识分享等支持。<br />
            5. 家长移动端+Web端在线系统：为家长第一时间了解孩子的学习进度、课堂表现、相关讯息等提供支持。<br />
          </div>
        </li>
        <li class="last">
          <img src="~img/cooperation/14.png">
        </li>
      </ul>
    </div>
    <div class="three">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>合作流程</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul class="three-list">
        <li>
          <img src="~img/cooperation/16.png">
          <p class="p1">Phase I</p>
          <p class="p2">第一阶段</p>
          <ul>
            <li>·初步电话沟通</li>
            <li>·发送介绍资料</li>
            <li>·当地市场初步调研</li>
            <li>·到访总部考察</li>
          </ul>
        </li>
        <li>
          <img src="~img/cooperation/17.png">
          <p class="p1">Phase II</p>
          <p class="p2">第二阶段</p>
          <ul>
            <li>·提交合作申请表</li>
            <li>·总部审核 </li>
            <li>·签订合同</li>
          </ul>
        </li>
        <li>
          <img src="~img/cooperation/18.png">
          <p class="p1">Phase III</p>
          <p class="p2">第三阶段</p>
          <ul>
            <li>·总部选址支持</li>
            <li>·设计和施工督导</li>
            <li>·中期和竣工验收</li>
            <li>·VI和SI指导</li>
            <li>·执照办理指导</li>
          </ul>
        </li>
        <li>
          <img src="~img/cooperation/19.png">
          <p class="p1">Phase IV</p>
          <p class="p2">第四阶段</p>
          <ul>
            <li>·总部协助发布招聘信息</li>
            <li>·总部协助复试</li>
            <li>·系统岗位线上辅助培训</li>
            <li>·总部集中线下培训</li>
          </ul>
        </li>
        <li>
          <img src="~img/cooperation/20.png">
          <p class="p1">Phase V</p>
          <p class="p2">第五阶段</p>
          <ul>
            <li>·指导完成运营教学准备工作</li>
            <li>·指导完成到点梳理各项工作</li>
            <li>·指导完成市场推广工作开业</li>
            <li>·到点运营教学支持</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="four">
      <div class="title-box">
        <img src="~img/index/02.png" class="title-icon" />
        <h4>合作意向表</h4>
        <img src="~img/index/03.png" class="title-icon" />
      </div>
      <ul>
        <li>
          <p>姓&emsp;&emsp;名</p>
          <input type="text" v-model="name" />
        </li>
        <li>
          <p>手机号码</p>
          <input type="text" v-model="mobile" />
        </li>
        <li>
          <p>合作省份</p>
          <div class="address">
            <v-distpicker :province="province" :city="city" :hide-area='true' @selected="onSelected">
            </v-distpicker>
          </div>
        </li>
      </ul>
      <div class="btn" @click="make">提交合作意向</div>
    </div>
    <div class="five">
      <div @click="$router.push({path:'/application'})">填写合作申请</div>
    </div>
    <consulting />
    <foot />
  </div>
</template>


<script>
import Navigation from "@/components/header.vue";
import footer from "@/components/footer.vue";
import VDistpicker from "v-distpicker";
import consulting from "@/components/consulting.vue";

export default {
  components: {
    Navigation,
    foot: footer,
    VDistpicker,
    consulting,
  },
  data() {
    return {
      path: "/cooperation",
      province: "", // 省份
      city: "", // 城市
      name: "", // 姓名
      mobile: "", // 电话
    };
  },
  methods: {
    onSelected(data) {
      this.province = data.province.value;
      this.city = data.city.value;
    },
    async make() {
      const res = await this.axios.post("api/index/make", {
        name: this.name,
        mobile: this.mobile,
        province: this.province,
        city: this.city,
      });
      if (res.status == 200 && res.data.code == 1) {
        console.log(res);
        const h = this.$createElement;
        this.$notify({
          title: "提示",
          message: h("i", { style: "color: teal" }, res.data.msg),
        });
      }
    },
  },
};
</script>


<style lang="less" scoped>
.cooperation {
  .title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .title-icon {
      width: 0.8rem;
      height: 0.38rem;
    }
    h4 {
      font-size: 0.45rem;
      line-height: 0.8rem;
      margin-left: 0.47rem;
      margin-right: 0.47rem;
      span {
        color: #4f82e1;
      }
    }
  }
  .one {
    padding-top: 1.23rem /* 123/100 */;
    margin-bottom: 1.65rem;
    .top-box {
      padding-right: 5.66rem /* 566/100 */;
      padding-left: 5.38rem /* 538/100 */;
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-top: 1.4rem /* 140/100 */;
      img {
        width: 1.11rem /* 111/100 */;
        margin: auto;
      }
      .p1 {
        font-size: 0.33rem /* 33/100 */;
        line-height: 0.46rem /* 46/100 */;
        margin-top: 0.4rem /* 40/100 */;
      }
      .p2 {
        font-size: 0.25rem /* 25/100 */;
        color: #666666;
        margin-top: 0.2rem /* 20/100 */;
      }
      .left {
        width: 2.81rem /* 281/100 */;
      }
      .shu {
        height: 2.5rem /* 150/100 */ /* 200/100 */;
        width: 0.02rem /* 5/100 */;
        background: #eee;
      }
      .right {
        width: 2.81rem /* 281/100 */;
      }
    }
    .pc {
      display: flex;
      padding-left: 3.27rem /* 327/100 */;
      padding-right: 2.9rem /* 307/100 */;
      justify-content: space-between;
      margin-top: 1.14rem /* 114/100 */;
      li {
        img {
          margin: auto;
          width: 0.96rem /* 96/100 */;
          margin-bottom: 0.5rem /* 50/100 */;
        }
        .p1 {
          font-size: 0.33rem /* 33/100 */;
        }
        .p2 {
          font-size: 0.25rem /* 25/100 */;
          text-align: center;
          margin-top: 0.2rem /* 20/100 */;
          color: #666666;
        }
      }
      li:nth-child(2) {
        img {
          width: 0.85rem;
        }
      }
      li:nth-child(3) {
        img {
          width: 1rem;
          margin-bottom: 0.55rem /* 50/100 */;
        }
      }
    }
    .web {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        text-align: center;
        margin-top: 50px;
        img {
          width: 50%;
          margin: auto;
        }
        .p1 {
          font-size: 20px;
          margin-top: 20px;
        }
        .p2 {
          font-size: 16px;
          color: #666666;
          margin-top: 10px;
        }
      }
    }
  }
  .tow {
    padding-top: 0.7rem;
    background: #eee;
    padding-bottom: 0.95rem;
    .bg-box {
      padding-top: 1.31rem;
      max-width: 12.88rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: auto;
      li {
        width: 3.22rem;
        height: 2.61rem;
        padding-top: 0.65rem;
        text-align: center;
        background: #fff;
        border: 1px solid #eee;
        position: relative;
        cursor: pointer;
        img {
          width: 1.03rem;
          margin: auto;
        }
        p {
          position: absolute;
          bottom: 10%;
          left: 50%;
          transform: translate(-50%);
          font-size: 0.25rem;
          color: #3e3a3a;
        }
        .text {
          width: 100%;
          height: 100%;
          padding: 0.2rem;
          text-align: left;
          font-size: 0.16rem;
          color: #fff;
          overflow: hidden;
          background: #65a6e8;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: 0.5s all;
          overflow-y: auto;
        }
        .text:hover {
          opacity: 1;
        }
      }
      .last {
        padding: 0;
        img {
          width: 100%;
        }
      }
    }
  }
  .three {
    background: #57585a;
    padding-top: 0.79rem;
    padding-bottom: 2.04rem;
    .title-box {
      h4 {
        color: #fff;
      }
    }
    .three-list {
      display: flex;
      padding-left: 3.69rem;
      padding-right: 3.19rem;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1.41rem;
      li {
        color: #fff;
        width: 2.32rem;
        img {
          width: 2.02rem;
        }
        .p1 {
          font-size: 0.14rem;
          padding-left: 0.4rem;
          margin-top: 0.31rem;
        }
        .p2 {
          font-size: 0.25rem;
          padding-left: 0.14rem;
        }
        ul {
          width: 1.6rem;
          margin-top: 0.23rem;
          li {
            font-size: 0.18rem;
            line-height: 0.3rem;
          }
        }
      }
      li:nth-child(5) {
        img {
          width: 1.31rem;
        }
      }
    }
    @media (max-width: 750px) {
      .three-list {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  .four {
    padding-top: 1.29rem /* 129/100 */;
    background: url("~img/cooperation/15.png") no-repeat;
    background-size: contain;
    padding-bottom: 1.41rem;
    background-position-y: bottom;
    ul {
      padding-top: 1.06rem /* 106/100 */;
      width: 50%;
      margin: auto;
      li {
        margin-bottom: 0.31rem /* 31/100 */;
        p {
          font-size: 0.35rem /* 35/100 */;
          float: left;
          line-height: 0.68rem /* 68/100 */;
          margin-right: 0.72rem /* 72/100 */;
        }
        input {
          width: 100%;
          max-width: 6.27rem /* 627/100 */;
          height: 0.68rem /* 68/100 */;
          border: 1px solid #666666;
          border-radius: 0.1rem /* 10/100 */;
          padding-left: 0.4rem /* 40/100 */;
          font-size: 0.2rem /* 20/100 */;
        }
        /deep/.distpicker-address-wrapper {
          display: inline-block;
          select {
            font-size: 0.2rem;
            padding: 0 10px 0 10px;
            line-height: 31px;
            height: 0.68rem;
            width: 3rem;
            border: 1px solid #666666;
            margin-right: 0.2rem;
          }
        }
      }
    }
    .btn {
      max-width: 4.22rem;
      height: 0.62rem;
      background: #383232;
      color: #fff;
      border-radius: 0.2rem;
      font-size: 0.35rem;
      margin: auto;
      text-align: center;
      line-height: 0.62rem;
      margin-top: 0.8rem;
      // margin-bottom: 1.41rem;
      cursor: pointer;
    }
  }
  .five {
    height: 2.8rem /* 280/100 */;
    background: rgb(247, 202, 3);
    padding-top: 0.77rem /* 77/100 */;
    div {
      width: 4.79rem /* 479/100 */;
      height: 1.3rem /* 130/100 */;
      border: 1px solid #000;
      border-radius: 0.2rem /* 10/100 */;
      font-size: 0.49rem /* 49/100 */;
      text-align: center;
      line-height: 1.3rem /* 130/100 */;
      margin: auto;
      cursor: pointer;
    }
  }
}
</style>